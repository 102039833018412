
import React from "react";
import { Link } from 'gatsby';
import { FormattedMessage, FormattedTimeParts, useIntl } from "react-intl";
import Layout from "../components/layout";
import SEO from "../components/seo"

// Styles
import styles from "../sass/pages/404.module.scss";

//Images
import errorImage from "../assets/img/404.svg"
import lines from "../assets/img/404lines.svg"

const NotFoundPage = () => {
  let lang = useIntl().locale
  const title = useIntl().formatMessage({ id: 'not_found' });
  
  return (
    <Layout color="">
      <SEO lang={lang} title={title} />
      <article className="section-fade">
        <div className={styles.lines}>
          <img src={lines} alt="404 lines" />
        </div>
        <div className={styles.center}>
          <img src={errorImage} alt="Bot" />
        </div>
        
      </article>
    </Layout>
  )
}

export default NotFoundPage;
